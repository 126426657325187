import * as React from 'react'
import { useSelector } from 'react-redux'
import { productsSelector } from '@store/slices/products-slice'
import { getProducts } from '@store/actions/products-actions'
import { PageLayout } from '@components/page-layout'
import { ProductCard } from '@components/product-card'
import { ItemLayout } from '@components/item-layout'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { CategorySidebar } from '@modules/shop/category-details/category-sidebar'
import { ContentLoader } from '@components/content-loader'
import { useAppDispatch } from '@store/hooks'
import { CategoryFilters } from '@modules/shop/category-details/filters/category-filters'
import { IsfSidebar } from '@modules/shop/common/isf-sidebar'
import { CategoryFiltersFormInputs, pageOptions, productSortOptions } from '@modules/shop/category-details'
import { useApiRequest } from '@hooks/use-api-request'
import { useSearchParams } from 'react-router-dom'

export const SearchView = (): JSX.Element => {
  const [page, setPage] = React.useState(1)

  const dispatch = useAppDispatch()
  const products = useSelector(productsSelector)
  const [searchParams] = useSearchParams()

  const search = searchParams.get('q') || ''
  console.log({ search })

  const { isLoading, action: getProductsWithParams } = useApiRequest(async () => {
    await dispatch(
      getProducts({
        search,
        page,
        page_size: watchedFilter.perPage?.value || '10',
        ordering: watchedFilter.ordering?.value || 'price_brutto',
        price_brutto: watchedFilter.price_brutto,
        destinations: watchedFilter.destinations,
        producers: watchedFilter.producers,
      }),
    )
  })

  React.useEffect(() => {
    if (search) {
      getProductsWithParams()
    }
  }, [search])

  const methods = useForm<CategoryFiltersFormInputs>({
    defaultValues: {
      ordering: productSortOptions[0],
      perPage: pageOptions[0],
      price_brutto: undefined,
      producers: [],
      destinations: [],
    },
  })

  const watchedFilter = useWatch({
    control: methods.control,
  })

  const onPageChange = (item: { selected: number }) => setPage(item.selected + 1)

  React.useEffect(() => {
    getProductsWithParams()
  }, [watchedFilter, page])

  return (
    <PageLayout
      sidebar={
        <FormProvider {...methods}>
          <CategorySidebar />
        </FormProvider>
      }
    >
      <IsfSidebar />

      <div>
        <ContentLoader isLoading={isLoading}>
          {!products.length ? (
            <p className="text-center">Nie znaleziono szukanego produktu ;-(</p>
          ) : (
            <FormProvider {...methods}>
              <ItemLayout
                perPage={watchedFilter.perPage?.value || '10'}
                filters={<CategoryFilters productSortOptions={productSortOptions} pageOptions={pageOptions} />}
                onPageChange={onPageChange}
              >
                {layoutView => (
                  <div className="row my-4 gy-4">
                    {products.map(product => (
                      <ProductCard layoutView={layoutView} key={product.id} product={product} />
                    ))}
                  </div>
                )}
              </ItemLayout>
            </FormProvider>
          )}
        </ContentLoader>
      </div>
    </PageLayout>
  )
}
