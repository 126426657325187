import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { commonObjectDelete, commonObjectGet, commonObjectPost, commonObjectPut } from '@store/actions/generic-actions'
import { OrderDetails } from '@models/order'
import { Product } from '@models/products'

export const getBasketDetails = createAsyncThunk<OrderDetails, void, { state: RootState }>(
  'basket/getBasketDetails',
  async (_, { getState }) =>
    await commonObjectGet<OrderDetails>(getState().websiteState.appData.urls.shop.basket_details),
)

export const getOrderDetails = createAsyncThunk<OrderDetails, string, { state: RootState }>(
  'basket/getOrderDetails',
  async token => await commonObjectGet<OrderDetails>(`api/shop/order/${token}/details/`),
)

interface AdBasketItemPayload extends RemoveBasketItemPayload {
  quantity: number
}

export const addBasketItem = createAsyncThunk<OrderDetails, AdBasketItemPayload, { state: RootState }>(
  'basket/addBasketItem',
  async ({ quantity, product }, { getState }) =>
    await commonObjectPost<OrderDetails>(getState().websiteState.appData.urls.shop.basket_items, {
      quantity,
      product: product.id,
    }),
)

export const updateBasketItem = createAsyncThunk<OrderDetails, AdBasketItemPayload, { state: RootState }>(
  'basket/updateBasketItem',
  async ({ quantity, product }, { getState }) =>
    await commonObjectPut<OrderDetails>(getState().websiteState.appData.urls.shop.basket_items, {
      quantity,
      product: product.id,
    }),
)

interface RemoveBasketItemPayload {
  product: Product
}

export const removeBasketItem = createAsyncThunk<OrderDetails, RemoveBasketItemPayload, { state: RootState }>(
  'basket/removeBasketItem',
  async ({ product }, { getState }) =>
    await commonObjectDelete<OrderDetails>(getState().websiteState.appData.urls.shop.basket_items, null, {
      product: product.id,
      quantity: 0,
    }),
)
