import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAppData } from '@store/actions/website-actions'
import { StandardPaginator } from '@models/base'
import { RootState } from '@store/index'
import { Destination, Producer } from '@models/shop'
import { Product } from '@models/products'

interface WebsiteAppDataUrlsProducts {
  products: string
  categories: string
}

interface WebsiteAppDataUrlsShop {
  basket_order: string
  basket_details: string
  basket_items: string
  basket_transport_options: string
}

interface WebsiteAppDataUrls {
  products: WebsiteAppDataUrlsProducts
  shop: WebsiteAppDataUrlsShop
}

interface ShopAppData {
  producers: Producer[]
  destinations: Destination[]
  latest_products: Product[]
  bestseller_products: Product[]
  frequently_purchased_products: Product[]
}

export interface WebsiteAppData {
  ready: boolean
  shop: ShopAppData
  urls: WebsiteAppDataUrls
}

export interface WebsiteState {
  standardPaginator: StandardPaginator
  appData: WebsiteAppData
  isFailure: boolean
}

export const emptyAppData: WebsiteAppData = {
  ready: false,
  shop: {
    destinations: [],
    producers: [],
    latest_products: [],
    bestseller_products: [],
    frequently_purchased_products: [],
  },
  urls: {
    shop: {
      basket_transport_options: '',
      basket_items: '',
      basket_details: '',
      basket_order: '',
    },
    products: {
      products: '',
      categories: '',
    },
  },
}

const initialState: WebsiteState = {
  appData: emptyAppData,
  isFailure: false,
  standardPaginator: {
    count: 0,
    next: '',
    page_link: '',
    previous: '',
  },
}

export const websiteSlice = createSlice({
  name: 'website',
  initialState,
  reducers: {
    setFailureStatus(state, action: PayloadAction<boolean>) {
      state.isFailure = action.payload
    },
    setStandardPaginator(state, action: PayloadAction<Partial<StandardPaginator>>) {
      state.standardPaginator = {
        count: action.payload?.count || 0,
        next: action.payload?.next || null,
        previous: action.payload?.previous || null,
        page_link: action.payload?.page_link || '',
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getAppData.fulfilled, (state, action) => {
      state.appData = action.payload
    })
  },
})
export const paginatorSelector = (state: RootState) => state.websiteState.standardPaginator

export const { setStandardPaginator, setFailureStatus } = websiteSlice.actions
export default websiteSlice.reducer
