import { useAppSelector } from '@store/hooks'
import { RootState } from '@store/index'
import { CategoryFilter } from '@modules/shop/category-details/filters/models'

export const useProductFilters = () => {
  const destinations = useAppSelector((state: RootState) => state.websiteState.appData.shop.destinations)
  const producers = useAppSelector((state: RootState) => state.websiteState.appData.shop.producers)

  const filters: CategoryFilter[] = [
    {
      key: 'producers',
      name: 'Producent',
      options: producers.map(row => ({ value: String(row.id), label: row.name })),
    },
    {
      key: 'destinations',
      name: 'Przeznaczenie',
      options: destinations.map(row => ({ value: String(row.id), label: row.name })),
    },
  ]

  return filters
}
