import * as React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { ProductDetailsView } from '@modules/shop/product-details'
import { CartView } from '@modules/shop/cart'
import { HomeView } from '@modules/home'
import { BlogView } from '@modules/blog'
import { ContactView } from '@modules/contact'
import { SeedCollectionView } from '@modules/shop/seed-collection'
import { CategoryDetailsView } from '@modules/shop/category-details'
import { BlogArticleView } from '@modules/blog/article'
import { OrderPaymentCompleteView } from '@modules/shop/order-payment-complete'
import { SearchView } from '@modules/shop/search'

type RouteTransitionStage = 'fade-in' | 'fade-out'

export const AppRoutes: React.FC = () => {
  const location = useLocation()

  const [displayLocation, setDisplayLocation] = React.useState(location)
  const [transitionStage, setTransitionStage] = React.useState<RouteTransitionStage>('fade-in')

  const handleAnimationEnd = () => {
    if (transitionStage === 'fade-out') {
      setTransitionStage('fade-in')
      setDisplayLocation(location)
    }
  }

  React.useEffect(() => {
    setTransitionStage('fade-in')
  }, [location, displayLocation])

  return (
    <div className={transitionStage} onAnimationEnd={handleAnimationEnd}>
      <Routes>
        <Route path={NavigationPath.Home} element={<HomeView />} />
        <Route path={NavigationPath.Blog} element={<BlogView />} />
        <Route path={NavigationPath.BlogArticle} element={<BlogArticleView />} />
        <Route path={NavigationPath.Contact} element={<ContactView />} />
        <Route path={NavigationPath.Home} element={<SeedCollectionView />} />
        <Route path={NavigationPath.Search} element={<SearchView />} />
        <Route path={NavigationPath.CategoryDetails} element={<CategoryDetailsView />} />
        <Route path={NavigationPath.ProductDetails} element={<ProductDetailsView />} />
        <Route path={NavigationPath.CartWithParamsAndToken} element={<CartView />} />
        <Route path={NavigationPath.CartWithParams} element={<CartView />} />
        <Route path={NavigationPath.Cart} element={<CartView />} />
        <Route path={NavigationPath.OrderPaymentComplete} element={<OrderPaymentCompleteView />} />
      </Routes>
    </div>
  )
}
