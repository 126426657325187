import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { CartSecondStepFormInputs } from '@modules/shop/cart/cart-second-step/index'
import classNames from 'classnames'
import { commonObjectPatch } from '@store/actions/generic-actions'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { RootState } from '@store/index'
import { updateBasketDetails } from '@store/slices/basket-slice'
import { useApiRequest } from '@hooks/use-api-request'

export const CartSecondStepPaymentMethod = (): JSX.Element => {
  const { setValue, control } = useFormContext<CartSecondStepFormInputs>()

  const handlePayu = () => setValue('payment_method', 'payu')
  const handleCashOnDelivery = () => setValue('payment_method', 'cash_on_delivery')

  const paymentMethod = useWatch({
    name: 'payment_method',
    control,
  })
  const dispatch = useAppDispatch()
  const orderUrl = useAppSelector((state: RootState) => state.websiteState.appData.urls.shop.basket_details)

  const { action: updatePaymentMethod } = useApiRequest(async payment_method =>
    dispatch(updateBasketDetails(await commonObjectPatch(orderUrl, { payment_method }))),
  )

  React.useEffect(() => {
    setValue('transport_option', undefined)
    updatePaymentMethod(paymentMethod)
  }, [paymentMethod])

  return (
    <>
      <p className="text-success fw-bold font-size-14">Wybierz formę płatności</p>
      <div className="d-flex my-4">
        <div
          className={classNames('border cursor-pointer', {
            'border-success': paymentMethod === 'payu',
          })}
          onClick={handlePayu}
        >
          <img src={require('@assets/images/payu.png')} alt="" height="80" />
        </div>
        <div
          onClick={handleCashOnDelivery}
          className={classNames('border fw-bold cursor-pointer ms-3 p-4', {
            'border border-success': paymentMethod === 'cash_on_delivery',
          })}
        >
          za pobraniem
        </div>
      </div>
    </>
  )
}
