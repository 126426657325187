import { WebsiteAppData, emptyAppData, setFailureStatus } from '@store/slices/website-slice'
import { HttpResources } from '@models/http-resources'
import { AppDispatch } from '@store/index'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { commonObjectGet } from '@store/actions/generic-actions'

export const getAppData = createAsyncThunk<WebsiteAppData, void, { dispatch: AppDispatch }>(
  'website/getAppData',
  async (_, { dispatch }) => {
    try {
      const data = await commonObjectGet<WebsiteAppData>(HttpResources.WebsiteAppData)
      await dispatch(setFailureStatus(false))
      return data
    } catch (e) {
      // Jesli bład 403 to pokazujemy komunikat że user jest niezalogowany
      if (e.response.status !== 403) {
        await dispatch(setFailureStatus(true))
      } else {
        throw e
      }
    }

    return emptyAppData
  },
)
