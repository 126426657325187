import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { CartSecondStepFormInputs } from '@modules/shop/cart/cart-second-step/index'
import { InputErrorMessage } from '@components/controls/input-error-message'
import classNames from 'classnames'

export const RulesCartSecondStepRules = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<CartSecondStepFormInputs>()
  return (
    <>
      <p className="text-success fw-bold font-size-14">Zaakceptuj warunki zamówienia</p>
      <div className="form-check">
        <input
          className={classNames('form-check-input border-green-light', {
            'is-invalid': !!errors['rules'],
          })}
          type="checkbox"
          value="true"
          id="rules"
          {...register('rules')}
        />
        <label className="form-check-label font-size-12" htmlFor="rules">
          * Oświadczam, że znam i akceptuję postanowienia Regulaminu Sklepu Internetowego DobreNasiona.pl
        </label>
        <InputErrorMessage name="rules" />
      </div>
      <div className="form-check my-4">
        <input
          className={classNames('form-check-input border-green-light', {
            'is-invalid': !!errors['rodo'],
          })}
          type="checkbox"
          value="true"
          id="rodo"
          {...register('rodo')}
        />
        <label className={classNames('form-check-label font-size-12 ')} htmlFor="rodo">
          Wyrażam zgodę na przekazanie moich danych osobowych, w postaci adresu e-mail, do Ceneo sp. z o. o. z siedzibą
          w Poznaniu (60-166), ul. Grunwaldzka 182 oraz przetwarzanie przez powyższy podmiot mojego adresu e-mail w celu
          zbadania opinii o poziomie zadowolenia z przeprowadzonego zakupu w DobreNasiona.pl.{' '}
        </label>
        <InputErrorMessage name="rodo" />
      </div>
    </>
  )
}
