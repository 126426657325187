import * as React from 'react'
import { ProductCard } from '@components/product-card'
import classnames from 'classnames'
import { CustomCarousel } from '@components/carousel/custom-carousel'
import { useAppSelector } from '@store/hooks'
import { RootState } from '@store/index'
import { Product } from '@models/products'

const productKinds = [
  { name: 'Nowości', type: 'latest_products' },
  { name: 'Bestsellery', type: 'bestseller_products' },
  { name: 'Najchętniej kupowane', type: 'frequently_purchased_products' },
]

export const HomeProductsList = (): JSX.Element => {
  const [activeSection, setActiveSection] = React.useState('latest_products')

  const isActive = section => activeSection === section

  const { latest_products, bestseller_products, frequently_purchased_products } = useAppSelector(
    (state: RootState) => state.websiteState.appData.shop,
  )

  const products: Product[] =
    {
      bestseller_products: bestseller_products,
      latest_products: latest_products,
    }[activeSection] || frequently_purchased_products

  return (
    <div className="d-none d-lg-block">
      <div className="mb-4">
        {productKinds.map(section => (
          <strong
            onClick={() => setActiveSection(section.type)}
            key={section.type}
            className={classnames(
              'font-size-22 me-5 cursor-pointer',
              isActive(section.type) ? 'text-warning' : 'text-success',
            )}
          >
            {section.name}
          </strong>
        ))}
      </div>

      <div className="home__gallery">
        <CustomCarousel
          nextButtonClass="home__gallery__next-button"
          previousButtonClass="home__gallery__previous-button"
        >
          {products.map(product => (
            <ProductCard key={product.id} product={product} className="col-lg-12" />
          ))}
        </CustomCarousel>
      </div>
    </div>
  )
}
