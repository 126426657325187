import { configureStore } from '@reduxjs/toolkit'
import authState from '@store/slices/auth-slice'
import websiteState from '@store/slices/website-slice'
import notificationState from '@store/slices/notification-slice'
import productsState from '@store/slices/products-slice'
import basketState from '@store/slices/basket-slice'

export const store = configureStore({
  reducer: { authState, websiteState, notificationState, productsState, basketState },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
