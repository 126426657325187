import { createAsyncThunk } from '@reduxjs/toolkit'
import { Category, Product, ProductDetails } from '@models/products'
import { commonObjectGet } from '@store/actions/generic-actions'
import { PaginationResponse } from '@models/base'
import { AppDispatch, RootState } from '@store/index'
import { setStandardPaginator } from '@store/slices/website-slice'

export const getCategories = createAsyncThunk<Category[], void, { state: RootState; dispatch: AppDispatch }>(
  'products/getCategories',
  async (_, { getState }) => {
    const url = getState().websiteState.appData.urls.products.categories

    return await commonObjectGet<Category[]>(url)
  },
)

export interface ProductsFilterParams {
  search?: string
  category_id: string
  page: number
  page_size: string
  ordering: string
  price_brutto: [number, number] | undefined
  destinations: string[]
  producers: string[]
}

export const getProducts = createAsyncThunk<
  Product[],
  Partial<ProductsFilterParams>,
  { dispatch: AppDispatch; state: RootState }
>('products/getProducts', async (params, { getState, dispatch }) => {
  const url = getState().websiteState.appData.urls.products.products

  const data = await commonObjectGet<PaginationResponse<Product>>(url, {
    ...params,
    price_brutto: undefined,
    min_price_brutto: params.price_brutto?.[0],
    max_price_brutto: params.price_brutto?.[1],
  })
  dispatch(setStandardPaginator(data))

  return data.results
})
export const getProductDetails = createAsyncThunk<ProductDetails, number, { state: RootState }>(
  'products/getProductDetails',
  async (productId, { getState }) => {
    const url = getState().websiteState.appData.urls.products.products

    return await commonObjectGet<ProductDetails>(`${url}${productId}/`)
  },
)
