export enum NavigationPath {
  Home = '/',
  Search = '/wyszukiwarka/',
  CategoryDetails = '/kategoria/:slug/:id',
  ProductDetails = '/produkt/:id/',
  Unauthorized = '/niezalogowany/',
  Failure = '/wystapil-blad/',
  Blog = '/blog/',
  BlogArticle = '/blog/:id/',
  About = '/o-nas/',
  Contact = '/kontakt/',
  Cart = '/koszyk/',
  CartWithParams = '/koszyk/:step/',
  CartWithParamsAndToken = '/koszyk/:step/:token/',
  OrderPaymentComplete = '/zamowienie/:token/payment-complete/',
}
